import { ref } from 'vue';
import { getData } from '@/mockData/AIHeuristicCard/categoryType';
export default {
  name: 'AlgorithmCard',
  setup() {
    const list = ref([]);
    function handleCategory(value) {
      return getData().find(item => item.value === value);
    }
    return {
      list,
      handleCategory
    };
  }
};