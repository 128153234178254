import picture from '@/assets/images/AIHeuristicCard/card/picture.png'
import pictureActive from '@/assets/images/AIHeuristicCard/card/picture_active.png'
import video from '@/assets/images/AIHeuristicCard/card/video.png'
import videoActive from '@/assets/images/AIHeuristicCard/card/video_active.png'
import text from '@/assets/images/AIHeuristicCard/card/text.png'
import textActive from '@/assets/images/AIHeuristicCard/card/text_active.png'
import voice from '@/assets/images/AIHeuristicCard/card/voice.png'
import voiceActive from '@/assets/images/AIHeuristicCard/card/voice_active.png'
import num from '@/assets/images/AIHeuristicCard/card/num.png'
import numActive from '@/assets/images/AIHeuristicCard/card/num_active.png'

import taskPicture from '@/assets/images/AIHeuristicCard/taskCard/picture.png'
import taskVideo from '@/assets/images/AIHeuristicCard/taskCard/video.png'
import taskText from '@/assets/images/AIHeuristicCard/taskCard/text.png'
import taskVoice from '@/assets/images/AIHeuristicCard/taskCard/voice.png'
import taskNum from '@/assets/images/AIHeuristicCard/taskCard/num.png'

import i18n from '@/language/index'

// 务必使用函数模式，否则改变语言环境，不会更新文本
export function getData() {
  return [{
    label: i18n.global.t('aiHeuristicCard.heuristicCard.categoryType.picture'),
    value: 1,
    imgUrl: picture,
    imgUrlActive: pictureActive,
    circleImg: taskPicture
  }, {
    label: i18n.global.t('aiHeuristicCard.heuristicCard.categoryType.video'),
    value: 2,
    imgUrl: video,
    imgUrlActive: videoActive,
    circleImg: taskVideo
  }, {
    label: i18n.global.t('aiHeuristicCard.heuristicCard.categoryType.text'),
    value: 3,
    imgUrl: text,
    imgUrlActive: textActive,
    circleImg: taskText
  }, {
    label: i18n.global.t('aiHeuristicCard.heuristicCard.categoryType.voice'),
    value: 4,
    imgUrl: voice,
    imgUrlActive: voiceActive,
    circleImg: taskVoice
  }, {
    label: i18n.global.t('aiHeuristicCard.heuristicCard.categoryType.number'),
    value: 5,
    imgUrl: num,
    imgUrlActive: numActive,
    circleImg: taskNum
  }]
}
