import jzsjwl from '@/assets/images/AIHeuristicCard/algorithmCard/jzsjwl.png'
import maskrcnnsf from '@/assets/images/AIHeuristicCard/algorithmCard/maskrcnnsf.png'
import sjfsc from '@/assets/images/AIHeuristicCard/algorithmCard/sjfsc.png'
import mobilesf from '@/assets/images/AIHeuristicCard/algorithmCard/mobilesf.png'
import bpfxcbsf from '@/assets/images/AIHeuristicCard/algorithmCard/bpfxcbsf.png'
import sdccwl from '@/assets/images/AIHeuristicCard/algorithmCard/sdccwl.png'
import lamasf from '@/assets/images/AIHeuristicCard/algorithmCard/lamasf.png'
import retinexsf from '@/assets/images/AIHeuristicCard/algorithmCard/retinexsf.png'
import scsdkwl from '@/assets/images/AIHeuristicCard/algorithmCard/scsdkwl.png'
import zcffxf from '@/assets/images/AIHeuristicCard/algorithmCard/zcffxf.png'
import xhsjwl from '@/assets/images/AIHeuristicCard/algorithmCard/xhsjwl.png'
import xhscdkwl from '@/assets/images/AIHeuristicCard/algorithmCard/xhscdkwl.png'
import kjzsf from '@/assets/images/AIHeuristicCard/algorithmCard/kjzsf.png'
import bsbyssf from '@/assets/images/AIHeuristicCard/algorithmCard/bsbyssf.png'
import zcxlj from '@/assets/images/AIHeuristicCard/algorithmCard/zcxlj.png'
import sjsl from '@/assets/images/AIHeuristicCard/algorithmCard/sjsl.png'
import ljhg from '@/assets/images/AIHeuristicCard/algorithmCard/ljhg.png'
import xxhg from '@/assets/images/AIHeuristicCard/algorithmCard/xxhg.png'
import kzjlflsf from '@/assets/images/AIHeuristicCard/algorithmCard/kzjlflsf.png'
import zsysjwl from '@/assets/images/AIHeuristicCard/algorithmCard/zsysjwl.png'

export default [
  {
    title: '卷积神经网络',
    titleEn: 'Convolutional Neural Network (CNN)',
    imgUrl: jzsjwl,
    categoryType: [1, 2],
    describe: '深度学习模型之一，可识别和分类图像中的特征',
    principle: '采用了卷积运算来提取图像的特征。它通过不断地对图像进行卷积和池化操作，来提取图像中的重要特征，再通过全连接层来分类。',
    application: '1.可用于自动驾驶汽车识别道路、交通信号灯、识别行人的场景。\n2.可用于医学肿瘤图像分析。'
  },
  {
    title: 'Mask R-CNN算法',
    titleEn: 'Mask R-CNN Algorithm',
    imgUrl: maskrcnnsf,
    categoryType: [1, 2],
    describe: '一种实例分割模型，通过确定图片中各个目标的位置和类别，给出像素级预测。“实例分割”指的是对图像中每个目标进行分割',
    principle: 'Mask R-CNN 算法具有两个阶段\n1.扫描图像并确定有可能包含目标的区域\n2.对可能包含目标的区域分类，并生成目标边界框。',
    application: '1.可用于自动驾驶汽车识别道路、交通信号灯、识别行人的场景。\n2.检测图像或视频中的特定目标\n3.检测图像或者视频中的所有目标主体'
  },
  {
    title: '神经辐射场',
    titleEn: 'Neural Radiance Fields',
    imgUrl: sjfsc,
    categoryType: [1, 2],
    describe: '一种基于三维数据的视觉模型，它的目标任务是对三维空间的数据进行建模，解决的问题是如何准确地对三维空间的数据进行构建。',
    principle: '通过神经网络来学习三维空间中物体的位置，颜色和形状信息，并建立一个对三维空间的准确描述。',
    application: '1.3D建模：NeRF算法可以通过分析多个视图图像，生成三维场景的高质量模型，并可以自动处理遮挡问题。\n2.虚拟现实：NeRF可以生成逼真的三维场景，为虚拟现实应用提供较好的数据支持。\n3.图像生成：NeRF可以通过把图像数据映射到三维空间中，并在三维空间中进行计算，生成高质量的图像。这对于图像恢复、图像美化等应用具有很大的价值。'
  },
  {
    title: 'Mobilenet算法',
    titleEn: 'Mobilenet Algorithm',
    imgUrl: mobilesf,
    categoryType: [1, 2],
    describe: '一种基于移动端的计算机视觉算法，旨在解决移动设备上的图像识别问题',
    principle: '通过减少神经网络的参数数量，并通过特殊的网络结构来降低计算量，使得算法适合在移动设备上运行。',
    application: '通过减少神经网络的参数数量，并通过特殊的网络结构来降低计算量，使得算法适合在移动设备上运行。'
  },
  {
    title: 'BP 反向传播算法',
    titleEn: 'Backpropagation Algorithm',
    imgUrl: bpfxcbsf,
    categoryType: [1, 3, 4, 5],
    describe: '是一种与最优化方法（如梯度下降法）结合使用，用来训练人工神经网络的常见方法，可帮助解决分类识别和回归预测等问题',
    principle: '通过比较输入数据的预测输出与实际输出之间的误差，调整神经网络中的参数，从而实现网络的自我学习。',
    application: '1.能自动识别输入数据与输出数据之间的关系，并通过反向传播的方式调整网络中的参数，从而实现高精度的分类识别和回归预测。\n2.帮助识别出人类说话的语音，并转化为文字。\n3.可以应用在电力预测系统中，通过分析历史电力数据和天气情况，预测未来电力需求的趋势，从而提供科学的决策支持。'
  },
  {
    title: '深度残差网络',
    titleEn: 'Deep Residual Network (ResNet)',
    imgUrl: sdccwl,
    categoryType: [1, 2],
    describe: '一种深度学习模型，用于解决深度网络的梯度消失和梯度爆炸问题，同时还能提高网络的准确率',
    principle: '通过残差块来解决深度网络的梯度消失和梯度爆炸问题，使得网络能够学到更深层次的特征。',
    application: '1.可以用于图像分类任务，比如分类图像是狗、猫、人等。\n2.可以用于图像语义分割，即将图像中的不同物体分成不同的区域。\n3.可以用于对象检测，即在图像中识别出特定的对象，并对其进行定位和识别。'
  },
  {
    title: 'LaMa算法',
    titleEn: 'LaMa Algorithm',
    imgUrl: lamasf,
    categoryType: [1, 2],
    describe: '深度学习模型之一，可识别和分类图像中的特征',
    principle: '利用神经网络，通过对大量的图像和视频进行学习，来识别图像和视频中的不同对象，并对它们进行分类和识别。',
    application: '1.重建图像或视频中丢失或损坏的部分的过程。\n2.删除图片中不需要的对象。'
  },
  {
    title: 'Retinex算法',
    titleEn: 'Retinex Algorithm',
    imgUrl: retinexsf,
    categoryType: [1, 2],
    describe: '一种图像增强算法，旨在通过减少图像中的固有噪声和增强图像的对比度来改善图像质量',
    principle: '原理基于人类视觉系统的观察模型，并通过对图像的不同波段的分析来实现增强效果。',
    application: '1.可以提高图像的对比度和鲜明度，使图像变得更清晰，更有立体感。\n2.可以通过分析图像中的颜色信息和亮度信息，去除图像中的噪声，提高图像的质量。\n3.可以将视频中的图像进行增强，提高视频的对比度和鲜明度，使视频变得更清晰，更有立体感。'
  },
  {
    title: '生成式对抗网络',
    titleEn: 'GAN',
    imgUrl: scsdkwl,
    categoryType: [1, 3],
    describe: '一种生成式模型，旨在通过两个神经网络模型——生成器和判别器之间的博弈来生成新的图像，它们相互对抗来提高生成的图像的真实感',
    principle: '生成对抗网络（GAN）由2个重要的部分构成：1. 生成器试图生成与训练数据相似的图像；2. 判别器试图区分真实数据与生成数据。',
    application: '1.可以生成抽象图像、艺术图像、动画、视频等。\n2.可以生成新的训练数据，以增加模型的泛化能力。\n3.可以把图像的风格迁移到另一张图像上，生成一张新的图像。\n4.可以修复损坏的图像，使其变得更清晰、更完整。'
  },
  {
    title: '主成分分析法',
    titleEn: 'Principal Component Analysis（PCA）',
    imgUrl: zcffxf,
    categoryType: [1, 4, 5],
    describe: 'PCA算法，主要用于数据降维，可以发现更利于人类理解的特征，压缩数据的同时能够让信息损失最小化。另外，还可应用在可视化以及去噪等等',
    principle: '通过正交变换将一组可能存在相关性的变量数据，转换为一组线性不相关的变量，转换后的变量被称为主成分。',
    application: '人脸图像数据有多个特征点，如时间、角度、亮度等。通过降维中的主成分分析法(PCA），能够降低图像的数据特征，从而减少冗余信息所造成的识别误差，提高了人脸识别精度和计算速度。'
  },

  {
    title: '循环神经网络',
    titleEn: 'Recurrent Neural Network (RNN)',
    imgUrl: xhsjwl,
    categoryType: [2, 3, 4],
    describe: '深度学习模型之一，它能够记忆之前的状态并将其用于当前计算，可以处理序列数据（如文本、语音、视频等）时，能考虑先前数据的影响',
    principle: 'RNN的基本结构包括输入层、隐藏层和输出层。在每个时间步骤中，RNN将当前的输入与之前隐藏层的状态结合起来生成新的隐藏层状态。这种循环连接的结构使得RNN能够保持对先前信息的记忆。',
    application: '1.语音识别\n2.语音识别\n3.机器翻译'
  },
  {
    title: '循环生成对抗网络',
    titleEn: 'CycleGAN',
    imgUrl: xhscdkwl,
    categoryType: [1],
    describe: '神经网络之一，主要用于Domain Adaption（域迁移）领域，能将真实照片转换成不同风格，但转换后的图片又能保留原始照片中的各种内容',
    principle: '学习两个域之间的两个数据转换函数。CycleGAN特点就是通过一个循环，首先将图像从一个域转换到另一个域，然后，再转回来，如果两次转换都很精准的话，那么，转换后的图像应该与输入的图像基本一致。通过这样的的一个循环，CycleGAN将转换前后图片的配对，提升了转换效果。',
    application: '画风迁移（如真实的风景变换到莫奈/梵高画作）'
  },
  {
    title: 'K-均值算法',
    titleEn: 'K-Means Algorithm',
    imgUrl: kjzsf,
    categoryType: [1, 3, 5],
    describe: '一种基于聚类算法，将数据点分到K个不同的簇中，对数据进行分类和聚类',
    principle: '对初始点进行随机分配，然后迭代更新点到簇中心的距离，直到点的分配稳定为止。',
    application: '数据挖掘、市场细分、客户细分等。比如，在电商平台上，可以使用K-means算法将客户分成不同的群体，以便进行定向营销。'
  },
  {
    title: '朴素贝叶斯算法',
    titleEn: 'K-Means AlgorithmNaive Bayesian Algorithm',
    imgUrl: bsbyssf,
    categoryType: [1, 3, 4],
    describe: '一种分类算法，它的目标任务是通过统计训练数据中各个特征与标签的联系，来对新数据进行分类预测',
    principle: '一种监督学习分类算法，它的目标任务是通过统计训练数据中各个特征与标签的联系，来对新数据进行分类预测。',
    application: '1.个性化邮件推荐\n2.垃圾邮件过滤\n3.新闻分类与主题识别'
  },
  {
    title: '支持向量机',
    titleEn: 'Support Vector Machine (SVM)',
    imgUrl: zcxlj,
    categoryType: [1, 2, 3, 5],
    describe: '一种二分类算法，可以用来解决分类和回归问题',
    principle: '寻找一个超平面使得将数据分割开来的同时最大化两类数据间的间隔。在高维空间的数据分类中表现良好。',
    application: '1.可用于二元分类和多元分类问题，如邮件垃圾过滤和手写数字识别。\n2.可用于回归问题，如预测股票价格或 房价。\n3.可用于检测异常点或离群值，如欺诈 检测或网络入侵检测。'
  },
  {
    title: '随机森林',
    titleEn: 'Random Forest',
    imgUrl: sjsl,
    categoryType: [1, 4, 5],
    describe: '一种用于分类和回归的算法，其目标是通过基于多个决策树的投票结果来预测目标变量',
    principle: '通过随机地从训练集中选择特征来建立多个决策树，然后将这些决策树的预测结果进行综合，以减少单个决策树的过拟合问题。',
    application: '1.用于银行信用评分卡风险评估；\n2.用于医疗数据分析，如肿瘤诊断；\n3.用于天气预报，如降水量预测。'
  },
  {
    title: '逻辑回归',
    titleEn: 'Logistic Regression',
    imgUrl: ljhg,
    categoryType: [3, 4, 5],
    describe: '一种二分类算法，它通过线性函数对数据进行建模，在预测离散值输出（如分类）时使用，可预测分类及概率',
    principle: '假设目标变量与自变量之间存在线性关系，并通过最大化似然函数来估计参数。然后将预测概率值进行阈值分类，来预测样本的类别和概率。',
    application: '1.用于银行信用评分卡风险评估；\n2.用于医疗数据分析，如肿瘤诊断；\n3.用于天气预报，如降水量预测。\n4.预测用户是否会点击广告；\n5.预测病人是否患有某种疾病；\n6.预测用户是否会购买某种商品。'
  },
  {
    title: '线性回归',
    titleEn: 'Linear Regression',
    imgUrl: xxhg,
    categoryType: [3, 4, 5],
    describe: '线性回归通过过去输入与输出变量的关系，帮助预测变量未来值',
    principle: '利用数理统计中回归分析，来确定两种或两种以上变量间相互依赖的定量关系的一种统计分析方法。',
    application: '预测消费者购买意愿\n通过建立多元线性回归模型，研究特征变量如消费者年龄、社交评论影响程度、消费者的时尚意识等对消费者购物行为的影响。'
  },
  {
    title: 'K最近邻分类',
    titleEn: 'K-Nearest Algorithm',
    imgUrl: kzjlflsf,
    categoryType: [1, 3, 4, 5],
    describe: 'KNN算法是一种基本的分类与回归算法，目标是对新数据进行类别及数值预测',
    principle: '根据新数据与训练数据之间的距离，选取最近的K个训练样本，根据它们的标签，对新数据进行分类。',
    application: '1.电商网站中推荐相似的物品或者内容给用户；\n2.根据不同病理特征来预测乳腺癌转移与否;'
  },
  {
    title: '自适应神经网络',
    titleEn: 'Adaptive Neural Networks',
    imgUrl: zsysjwl,
    categoryType: [1, 3, 4, 5],
    describe: '一种自适应的机器学习算法，旨在解决回归和分类问题。它的目标任务是构建一个自适应的多层神经网络，以最大程度地提高预测精度',
    principle: '通过在训练过程中动态调整网络结构和参数，以适应不同数据集的需求。',
    application: '1.图像分类，如人脸识别和物体识别。\n2.语音识别，如语音识别和语音合成。\n3.自然语言处理，如文本分类和文本生成。\n4.基因表达数据分析，如基因表达数据的聚类分析和预测。'
  }
]
