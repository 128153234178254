import algorithmData from '@/mockData/AIHeuristicCard/algorithmData'
import taskData from '@/mockData/AIHeuristicCard/taskData'
// 算法卡片数据查询
export function getAlgData (params) {
  let res = [...algorithmData]
  // 多条件查询
  Object.keys(params).forEach(item => {
    if (params && params[item]) {
      res = res.filter(it => it[item]?.includes(params[item]))
    }
  })
  return res
}
// 任务卡片数据查询

export function getTaskData (params) {
  let res = [...taskData]
  // 多条件查询
  Object.keys(params).forEach(item => {
    if (params && params[item]) {
      res = res.filter(it => it[item]?.includes(params[item]))
    }
  })
  return res
}
