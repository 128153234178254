import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import HeuristicCard from './Component/Index.vue';
export default {
  __name: 'Index',
  setup(__props) {
    // import Header from '@/components/Header'
    // import PageFooter from '@/components/PageFooter'

    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(HeuristicCard);
    };
  }
};