import imageClassification from '@/assets/images/AIHeuristicCard/taskCard/image-classification.png'
import threeDimensional from '@/assets/images/AIHeuristicCard/taskCard/three-dimensional.png'
import imgFill from '@/assets/images/AIHeuristicCard/taskCard/img-fill.png'
import imgSearch from '@/assets/images/AIHeuristicCard/taskCard/image-search.png'
import styleTransfer from '@/assets/images/AIHeuristicCard/taskCard/style-transfer.png'
import imgEnhance from '@/assets/images/AIHeuristicCard/taskCard/image-enhance.png'
import pose from '@/assets/images/AIHeuristicCard/taskCard/pose-recognition.png'
import textRecognition from '@/assets/images/AIHeuristicCard/taskCard/text-recognition.png'
import videoSegment from '@/assets/images/AIHeuristicCard/taskCard/video-segment.png'
import videoTarget from '@/assets/images/AIHeuristicCard/taskCard/video-target-tracking.png'
import videoAbstraction from '@/assets/images/AIHeuristicCard/taskCard/video-abstraction.png'
import textImg from '@/assets/images/AIHeuristicCard/taskCard/text-img.png'
import textClassification from '@/assets/images/AIHeuristicCard/taskCard/text-classification.png'
import sentimentAnalysis from '@/assets/images/AIHeuristicCard/taskCard/sentiment-analysis.png'
import sequenceLabel from '@/assets/images/AIHeuristicCard/taskCard/sequence-label.png'
import machineTranslation from '@/assets/images/AIHeuristicCard/taskCard/machine-translation.png'
import intentionRecognition from '@/assets/images/AIHeuristicCard/taskCard/intention-recognition.png'
import speechRecognition from '@/assets/images/AIHeuristicCard/taskCard/speech-recognition.png'
import dataMin from '@/assets/images/AIHeuristicCard/taskCard/data-mining.png'
import backPrediction from '@/assets/images/AIHeuristicCard/taskCard/back-prediction.png'
import targeDetection from '@/assets/images/AIHeuristicCard/taskCard/targe-detection.png'

export default [
  {
    title: '图像分类',
    titleEn: 'Image Classification',
    imgUrl: imageClassification,
    categoryType: [1],
    buttonList: [
      { title: '网盘图片自动分类' },
      { title: 'AI智能垃圾分类' },
      { title: '手写数字分类' },
      { title: '图像纹理分类' }
    ],
    interpretation: '图像分类，根据图像信息中所反映出来的不同特征，把不同类别的目标区分开来的图像处理方法。它利用计算机对图像进行定量分析，把图像或图像中的每个像元或区域划归为若干个类别中的某一种，以代替人的视觉判读。',
    solve: '朴素贝叶斯分类器\nK最近邻分类算法\n支持向量机\n随机森林\n卷积神经网络深度残差网络\n自适应神经网络\nMobilenet算法\n主成分分析\n人工神经网络'
  },
  {
    title: '目标检测',
    titleEn: 'Object Detection',
    imgUrl: targeDetection,
    categoryType: [1],
    buttonList: [
      { title: '人脸识别' },
      { title: '行人检测' },
      { title: '文本检测' }
    ],
    interpretation: '图像分类，根据图像信息中所反映出来的不同特征，把不同类别的目标区分开来的图像处理方法。它利用计算机对图像进行定量分析，把图像或图像中的每个像元或区域划归为若干个类别中的某一种，以代替人的视觉判读。',
    solve: '朴素贝叶斯分类器\nK最近邻分类算法\n支持向量机\n随机森林\n卷积神经网络深度残差网络\n自适应神经网络\nMobilenet算法\n主成分分析\n人工神经网络'
  },
  {
    title: '三维重建',
    titleEn: '3D Reconstruction',
    imgUrl: threeDimensional,
    categoryType: [1],
    buttonList: [
      { title: '灾难事故现场重构' },
      { title: '历史文物重建' },
      { title: '外科手术3D成像' }
    ],
    interpretation: '三维重建是指对三维物体建立适合计算机表示和处理的数学模型,是在计算机环境下对其进行处理、操作和分析其性质的基础,也是在计算机中建立表达客观世界的虚拟现实的关键技术。',
    solve: '神经辐射场'
  },
  {
    title: '图像填充',
    titleEn: 'Image Inpainting',
    imgUrl: imgFill,
    categoryType: [1],
    buttonList: [
      { title: '图像复原' },
      { title: '图像编辑' },
      { title: '图像目标消除' }
    ],
    interpretation: '从缺失的图片目标区域中合理地恢复内容，以使图片恢复至未缺失之前的状态，或者消除图片中不想出现的内容。,是在计算机环境下对其进行处理、操作和分析其性质的基础,也是在计算机中建立表达客观世界的虚拟现实的关键技术。',
    solve: 'LaMa算法\nRetinex算法\nGAN'
  },
  {
    title: '图像检索',
    titleEn: 'Image Retrieval',
    imgUrl: imgSearch,
    categoryType: [1],
    buttonList: [
      { title: '电商商品检索' },
      { title: '布料检索' },
      { title: '版权保护' },
      { title: '医疗影像诊断' }
    ],
    interpretation: '从图片检索数据库中检索出满足条件的图片，其中包括两类：1）基于文本的图像检索技术（TBIR），利用文本描述如文本描述图片的内容、作者等等来检索图片；2）基于内容的图像检索技术（CBIR），利用图片的颜色、纹理及图片包含的物体、类别等信息检索图片。,是在计算机环境下对其进行处理、操作和分析其性质的基础,也是在计算机中建立表达客观世界的虚拟现实的关键技术。',
    solve: '卷积神经网络\nMobilenet算法\nMask R-CNN\n自适应神经网络\n深度残差网络\nK最近邻分类算法'
  },
  {
    title: '图像/视频风格迁移',
    titleEn: 'Neural Style',
    imgUrl: styleTransfer,
    categoryType: [1],
    buttonList: [
      { title: '照片艺术风格化' },
      { title: '视频艺术风格化' },
      { title: '个性滤镜' }
    ],
    interpretation: '图像风格迁移指的是两个不同域中图像的转换，具体来说就是提供一张风格图像，将任意一张图像转化为这个风格，并尽可能保留原图像的内容。',
    solve: 'CycleGAN\nGAN'
  },
  {
    title: '图像增强',
    titleEn: 'Image Enhancement',
    imgUrl: imgEnhance,
    categoryType: [1],
    buttonList: [
      { title: '模糊图片清晰化' },
      { title: '均衡图像亮度' },
      { title: '图像视频去雾' }
    ],
    interpretation: '图像增强就是增强图像中用户感兴趣的信息, 其主要目的有两个: 一是改善图像的视觉效果, 提高图像成分的清晰度; 二是使图像变得更有利于计算机处理。',
    solve: 'Retinex算法\nLaMa算法\nCycleGAN'
  },
  {
    title: '姿态识别',
    titleEn: 'Posture Recognition',
    imgUrl: pose,
    categoryType: [1],
    buttonList: [
      { title: '步态识别安防' },
      { title: '异常行为检测' },
      { title: '体感游戏' }
    ],
    interpretation: '人体的姿态是人体重要的生物特征之一，姿态识别能让计算机知道人在做什么、识别出这个人是谁。姿态识别包括人体分割和人体姿势识别，使用的方法包括人体骨骼关键点检测、动作识别、身份识别等等。',
    solve: 'Mask R-CNN'
  },
  {
    title: '文字识别',
    titleEn: 'Optical Character Recognition',
    imgUrl: textRecognition,
    categoryType: [1],
    buttonList: [
      { title: '文档识别' },
      { title: '证件识别' },
      { title: '单据识别' }
    ],
    interpretation: '运用光学技术和计算机技术对图像中的文字进行检测，然后识别出图像中的文字内容，是计算机视觉研究领域的分支之一。',
    solve: '卷积神经网络\n深度残差网络\n支持向量机\n人工神经网络'
  },
  {
    title: '视频分割',
    titleEn: 'Video Segmentation',
    imgUrl: videoSegment,
    categoryType: [2],
    buttonList: [
      { title: '自动驾驶环境感知' },
      { title: 'AI视频剪辑工具' },
      { title: '视频人像抠图' }
    ],
    interpretation: '视频分割有空间信息和时序信息，可分为三类：1）视频目标分割：从视频所有图像中把感兴趣的物体区域完整的分割出来；2）视频语义分割：给视频中所有图像上的每一个像素赋予一个带有语义的标签；3）视频全景分割的：精确定位整个视频的所有语义和实例边界，并为这些分割的视频管分配正确的标签。',
    solve: 'LSTM长短期记忆\n支持向量机\nMobilenet算法\nMask R-CNN'
  },
  {
    title: '视频目标追踪',
    titleEn: 'Video Object Tracking',
    imgUrl: videoTarget,
    categoryType: [2],
    buttonList: [
      { title: '公共安防追踪' },
      { title: '自动驾驶轨迹规划' },
      { title: '机器人视觉导航' }
    ],
    interpretation: '视频目标跟踪是指在视频序列第一帧指定目标后，在后续帧持续跟踪目标，即利用边界框标定目标，实现目标的定位与尺度估计。目标跟踪问题通常分为单目标跟踪和多目标跟踪。',
    solve: '支持向量机\nMask R-CNN\nLSTM长短期记忆\nMobilenet算法'
  },
  {
    title: '视频摘要',
    titleEn: 'Video Abstract',
    imgUrl: videoAbstraction,
    categoryType: [2],
    buttonList: [
      { title: '公安刑侦监控视频分析' },
      { title: '影视行业精彩视频集锦' },
      { title: '海量视频检索系统' }
    ],
    interpretation: '以自动或半自动的方式，通过分析视频的结构和内容存在的时空冗余，从原始视频中提取有意义的片段/帧，可分为两种：1）第一种是直接提取视频中的关键帧合成新的视频；2）第二种又称视频浓缩，是对视频内容的一个简单概括。',
    solve: '卷积神经网络\n深度残差网络\nLSTM长短期记忆\nMask R-CNN\nMobilenet\n支持向量机'
  },
  {
    title: '文本生成图像',
    titleEn: 'Text-to-Image',
    imgUrl: textImg,
    categoryType: [2],
    buttonList: [
      { title: 'Stable Diffusion 文本生成图像' },
      { title: '新闻报道图像生成' },
      { title: 'AI绘画创作' }
    ],
    interpretation: '使用人工智能技术将文本转换为图像的过程，其可以根据给定文本生成符合描述的真实图像。',
    solve: 'GAN'
  },
  {
    title: '文本分类',
    titleEn: 'Text Classification',
    imgUrl: textClassification,
    categoryType: [3],
    buttonList: [
      { title: '过滤垃圾邮件' },
      { title: '新闻分类' },
      { title: '识别不良言论' },
      { title: '词性标注' }
    ],
    interpretation: '文本分类任务是指在给定的分类体系中，将文本指定分到某个或某几个类别中，被分类的对象有如句子、标题、商品评论等短文本，也有如文章等长文本。',
    solve: '支持向量机\n逻辑回归\n朴素贝叶斯\n循环神经网络\nK最近邻分类算法\n随机森林\n遗传算法\nK-均值算法\nLSTM长短期记忆\n线性回归\n人工神经网络'
  },
  {
    title: '情感分析',
    titleEn: 'Sentiment Analysis',
    imgUrl: sentimentAnalysis,
    categoryType: [3],
    buttonList: [
      { title: '电商平台商品评论分析' },
      { title: '社交媒体舆情报告' },
      { title: '聊天机器人问答系统' }
    ],
    interpretation: '对人们在产品、服务、事件、话题等方面的观点、情感、情绪、评价和态度的计算研究。它是对带有情感色彩的主观性文本进行分析、处理、归纳和推理的过程。',
    solve: '逻辑回归\n朴素贝叶斯\n支持向量机\nLSTM长短期记忆\nK最近邻分类算法\nK-均值算法\n随机森林\n线性回归\n遗传算法\n人工神经网络'
  },
  {
    title: '序列标注',
    titleEn: 'Sequence Labeling',
    imgUrl: sequenceLabel,
    categoryType: [3],
    buttonList: [
      { title: '快递单信息智能填充' },
      { title: '合同智能审核' }
    ],
    interpretation: '即给定一个输入序列，使用模型对这个序列的每一个位置标注一个相应的标签，是一个序列到序列的过程。常见的子任务包括分词 、命名实体识别、词性标注、关键词抽取、语义角色标注等。',
    solve: 'LSTM长短期记忆\n循环神经网络\n朴素贝叶斯\n自适应神经网络'
  },
  {
    title: '机器翻译',
    titleEn: 'Machine Translation',
    imgUrl: machineTranslation,
    categoryType: [3],
    buttonList: [
      { title: '智能翻译器' },
      { title: '语音同传' },
      { title: '跨语言检索' }
    ],
    interpretation: '机器翻译是计算语言学的一个分支，主要研究如何将一种语言的源文本自动转换为另一种语言的文本。',
    solve: '循环神经网络\n图神经网络\n支持向量机\nLSTM长短期记忆\nK最近邻分类算法\n人工神经网络'
  },
  {
    title: '意图识别',
    titleEn: 'Intent Recognition',
    imgUrl: intentionRecognition,
    categoryType: [3],
    buttonList: [
      { title: 'ChatGPT 聊天机器人程序' },
      { title: '智能客服系统' },
      { title: '搜索引擎' }
    ],
    interpretation: '通过分类的办法将搜索查询分到相应的意图种类。例如，当用户输入一段文本时，意图识别可以准确识别出它是哪个领域的问题，从而给用户提供最相关的信息或服务。',
    solve: '循环神经网络\n支持向量机\n图神经网络\n遗传算法\n逻辑回归\n线性回归\nLSTM长短期记忆\n随机森林\nK最近邻分类算法\nK-均值算法\n人工神经网络'
  },
  {
    title: '语音识别',
    titleEn: 'Speech Recognition',
    imgUrl: speechRecognition,
    categoryType: [4],
    buttonList: [
      { title: '智能音箱' },
      { title: '智能安防身份识别' },
      { title: '语音智能代理' }
    ],
    interpretation: '语音识别技术，也被称为自动语音识别（Automatic Speech Recognition，ASR)，其目标是将人类的语音中的词汇内容转换为计算机可读的输入，例如按键、二进制编码或者字符序列。',
    solve: 'BP 反向传播算法\n自适应神经网络\nLSTM长短期记忆\n循环神经网络\n线性回归\n支持向量机\n随机森林\n遗传算法\n朴素贝叶斯\n人工神经网络'
  },
  {
    title: '数据挖掘',
    titleEn: 'Data Mining',
    imgUrl: dataMin,
    categoryType: [5],
    buttonList: [
      { title: '定向营销' },
      { title: '电商网站商品推荐' },
      { title: '用户偏好推荐' },
      { title: '搜索推荐' }
    ],
    interpretation: '数据挖掘（Data Mining）就是从大量的、不完全的、有噪声的、模糊的、随机的实际应用数据中，提取隐含在其中的、人们事先不知道的、但又是潜在有用的信息和知识的过程。',
    solve: 'K均值算法\n支持向量机\nK最近邻分类算法\n图神经网络\n随机森林\n遗传算法\n逻辑回归\n人工神经网络'
  },
  {
    title: '回归预测',
    titleEn: 'Regression prediction',
    imgUrl: backPrediction,
    categoryType: [5],
    buttonList: [
      { title: '房价预测' },
      { title: '银行信用风险评估' },
      { title: '股票预测' }
    ],
    interpretation: '回归预测是一种利用统计建模去预测未来的一种方法,通过研究已有的自变量和因变量的关系,找出自变量和因变量的数学关系,再通过给定的自变量和数学关系去预测因变量。',
    solve: '线性回归\n支持向量机\nLSTM长短期记忆\n逻辑回归\n随机森林\n图神经网络\n主成分分析\n人工神经网络'
  }
]
