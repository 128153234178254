import { ref, watch, nextTick, computed } from 'vue';
import { getData } from '@/mockData/AIHeuristicCard/categoryType';
import AlgorithmCard from './AlgorithmCard.vue';
import TaskCard from './TaskCard.vue';
import { getAlgData, getTaskData } from '@/api/AIHeuristicCard';
export default {
  name: 'HeuristicCard',
  components: {
    AlgorithmCard,
    TaskCard
  },
  setup() {
    const title = ref('');
    const type = ref(1);
    const searchForm = ref({
      categoryType: '',
      title: ''
    });
    const algorithmRef = ref();
    const taskRef = ref();
    const categoryList = computed(() => getData());
    watch(() => searchForm, newValue => {
      nextTick(() => {
        handleSearch();
      });
    }, {
      deep: true,
      immediate: true
    });
    // 切换事件
    function handleChange(val) {
      type.value = val;
      handleSearch();
    }
    // 查询方法
    function handleSearch() {
      if (type.value === 1) {
        algorithmRef.value.list = getAlgData(searchForm.value) || [];
      } else {
        taskRef.value.list = getTaskData(searchForm.value) || [];
      }
    }
    return {
      categoryList,
      title,
      type,
      algorithmRef,
      taskRef,
      searchForm,
      handleChange,
      handleSearch
    };
  }
};