import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e26eac6e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "heuristic-card"
};
const _hoisted_2 = {
  class: "heuristic-card-top"
};
const _hoisted_3 = {
  class: "search-box"
};
const _hoisted_4 = {
  class: "search-box-content"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "search-content"
};
const _hoisted_7 = {
  class: "btns"
};
const _hoisted_8 = {
  class: "heuristic-card-content"
};
const _hoisted_9 = {
  class: "search-category"
};
const _hoisted_10 = {
  class: "search-category-list"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_algorithm_card = _resolveComponent("algorithm-card");
  const _component_task_card = _resolveComponent("task-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.title')), 1), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_input, {
    class: "custom-search-input",
    type: "text",
    modelValue: $setup.title,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.title = $event)
  }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
    onClick: _cache[1] || (_cache[1] = $event => $setup.searchForm.title = $setup.title)
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.btns.search')), 1)]),
    _: 1
  })]), _createElementVNode("div", null, _toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.tips.input')), 1)])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
    onClick: _cache[2] || (_cache[2] = $event => $setup.handleChange(1)),
    class: _normalizeClass({
      active: $setup.type == 1
    })
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.btns.AlgorithmCard')), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_el_button, {
    onClick: _cache[3] || (_cache[3] = $event => $setup.handleChange(2)),
    class: _normalizeClass({
      active: $setup.type == 2
    })
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.btns.taskCard')), 1)]),
    _: 1
  }, 8, ["class"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.tips.select')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categoryList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass({
        'search-category-item': true,
        active: $setup.searchForm.categoryType === item.value
      }),
      onClick: $event => $setup.searchForm.categoryType = $setup.searchForm.categoryType === item.value ? '' : item.value
    }, [_createElementVNode("img", {
      src: $setup.searchForm.categoryType === item.value ? item.imgUrlActive : item.imgUrl
    }, null, 8, _hoisted_12), _createElementVNode("span", null, _toDisplayString(item.label), 1)], 10, _hoisted_11);
  }), 128))]), _createElementVNode("div", {
    class: "search-all",
    onClick: _cache[4] || (_cache[4] = $event => $setup.searchForm.categoryType = '')
  }, _toDisplayString(_ctx.$t('aiHeuristicCard.heuristicCard.btns.showAll')), 1)]), _withDirectives(_createVNode(_component_algorithm_card, {
    class: "search-list",
    ref: "algorithmRef"
  }, null, 512), [[_vShow, $setup.type == 1]]), _withDirectives(_createVNode(_component_task_card, {
    class: "search-list",
    ref: "taskRef"
  }, null, 512), [[_vShow, $setup.type == 2]])])]);
}